.alert {
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
  }
  
  .danger {
    color: #f44336;
    background-color: white;
  }
  
  .warning {
    color: #ff9800;
    background-color: white;
  }
  
  .info {
    color: #00bcd4;
    background-color: white;
  }
  
  .success {
    color: #4caf50;
    background-color: white;
  }
  .closeBtn {
    font-size: 20px;
    background-color: transparent;
    border: none;
    color: gray;
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
  