.container {
    /* position: relative; */
    width: 75px;
    height: 100%;
  }

  .container ~ div, .container ~ section{
    margin-left: 75px;
  }
  .container ~ div, .container ~ section{
    transition: margin-left 1s ease;
  }
  
  
  .container:hover ~ div, .container:hover ~ section {
    margin-left: 250px;
    transition: margin-left 1s ease;
  }
  
  @media screen and (max-width:576px) {
    .container:hover ~ div, .container:hover ~ section {
    margin-left: 75px;
    transition: margin-left 1s ease;
  }
  }
  
  .navigation{
    position: fixed;
    font-weight: 400;
    font-family: 'Space Grotesk', sans-serif;
    width: 75px;
    height: 100%;
    background-color: var(--white);
    transition: width 1s ease;
    border-right: solid 1px #00000028;
    z-index: 5;
    overflow-x: hidden;
  }
  .navigation:hover{
    width: 250px;
  }
  .navigation ul{
    position: relative;
  }
  .navigation ul li a .title {
    position: relative;
    top: 18px;
    margin-left: 30px;
    font-weight: 600;
    margin-bottom: 30px;
    font-family: 'Quattrocento', serif;
  } 
  .navigation .icon1 img{
    width: 50px;
    height: 50px;
    position: relative;
    top: 10px;
    left: 8px;
  
  }
  .logo{
    display: flex;
    flex-direction: row;
  }
  .navigation ul li{
    margin: 3px;
    overflow: hidden;
    position: relative;
  }
  /* .navigation a:hover span.title{
    color: red;
    border: solid var(--black) 5px;
  } */
  .navigation a{
    display: flex !important;
    flex-wrap: nowrap;
    width: 245px;
    word-wrap: nowrap;
    margin-left: 10px;
    padding: 13px 10px;
    color: var(--grey);
    text-decoration: none;
    transition-duration: 0.8s;
    border-radius: 10px;
    display: block;
    font-size: 15px;
  }
  .navigation a:hover{
    color: var(--black);
    background-color: #057d7d3a;
  }
  .navigation a:has(span.active1){
    color: var(--green);
  }
  .active1{
    border: solid var(--green) 3px;
    border-bottom-left-radius: 10px;
    color: var(--green);
    border-top-left-radius: 10px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .navigation .sideIcons{
    margin-right: 40px;
    visibility: visible;
    width: 20px;
    height: 20px;
  }
  .focus{
    width: 250px;
  }
  
  @media (min-height: 380px) and (max-height: 434px) {
    .navigation a{
      padding: 1px 10px;
    }
  }
  @media (min-height: 435px) and (max-height: 521px) {
    .navigation a{
      padding: 4px 10px;
    }
  }
  @media (min-height: 522px) and (max-height: 625px) {
    .navigation a{
      padding: 8px 10px;
    }
  }
  
  /*
  .navigation.active {
    width: 75px;
  }
  
  .navigation ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  .navigation ul li {
    position: relative;
    width: 200%;
    list-style: none;
    transition: 0.5s;
  }
  
  .navigation ul li:hover,
  .navigation ul li.hovered {
    background-color: rgb(70, 70, 70);
    transition: 0.5s;
  }
  
  .navigation ul li:nth-child(1) {
    margin-bottom: 40px;
  }
  
  .navigation ul li a {
    position: relative;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
  
  .navigation ul li:hover a,
  .navigation ul li.hovered a {
    color: rgb(255, 255, 255);
  }
  
  .navigation ul li a .icon,
  .icon1 {
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 90px;
    text-align: center;
  }
  
  .navigation ul li a .icon img {
    height: 40px;
  }
  
  .navigation ul li a .icon1 img {
    height: 50px;
  }
  
  .navigation ul li a .title {
    position: relative;
    display: block;
    padding: 0 10px;
    height: 60px;
    line-height: 60px;
    text-align: start;
    white-space: nowrap;
  }
  
  .navigation ul li:hover a::before,
  .navigation ul li.hovered a::before {
    content: "";
    position: absolute;
    right: 0;
    top: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 35px 35px 0 10px rgb(255, 255, 255);
    pointer-events: none;
  }
  
  .navigation ul li:hover a::after,
  .navigation ul li.hovered a::after {
    content: "";
    position: absolute;
    right: 0;
    top: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 35px -35px 0 10px white;
    pointer-events: none;
  } */
  
  /* .main {
    position: absolute;
    width: calc(100% - 300px);
    left: 300px;
    min-height: 100vh;
    background: white;
    transition: 0.5s;
    align-items: center;
    justify-content: center;
  }
  
  .main.active {
    width: calc(100% - 75px);
    left: 75px;
  }
  
  .main img {
    height: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .topbar {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: rgb(255, 255, 255);
  } */
  
  /* Responsive Sellular Title */
  
  /* .header-title {
    color: rgb(255, 255, 255);
    margin: auto;
    font-size: 25px;
    display: block;
    text-align: left;
  }
  
  .header-title .icon2 {
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 90px;
  }
  
  .header-title .icon2 img {
    height: 50px;
    width: 44px;
  }
  
  .header-title .title2 {
    position: relative;
    display: block;
    padding: 0 5px;
    height: 60px;
    line-height: 60px;
    text-align: right;
    white-space: nowrap;
    font-size: 22px;
  }
  
  .header-title {
    display: none;
  }*/
  
  /* Menu Toggler */
  
  /* .toggle {
    position: relative;
    width: 70px;
    height: 70px;
    display: flex;
    font-size: 2.5em;
    cursor: pointer;
    background-color: transparent;
  }
  
  .toggle {
    display: none;
  } */
  
  /* Search Bar */
  /*
  .search {
    position: relative;
    width: 400px;
    margin: 0 10px;
  }
  
  .search label {
    position: relative;
    width: 100%;
  }
  
  .search label input {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    padding: 5px 20px;
    padding-left: 45px;
    font-size: 18px;
    outline: none;
    border: 1px solid black;
  }
  
  .search label img {
    position: absolute;
    top: 0;
    left: 10px;
    height: 25px;
  } */
  
  /* Profile icon */
  
  /* .user {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  
  .user img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
  
  /* 5 cards */
  .card-container {
    margin-left: 75px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: 'Space Grotesk', sans-serif;
  }
  .cards {
    --cards-text-color: white;
    --cards-closed-size: 7rem;
    --animation-speed-normal: .5s;
    --animation-speed-fast: .25s;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  
  .card {
    width: 700px;
    height: var(--cards-closed-size);
    overflow: hidden;
    border-radius: 1rem;
    position: relative;
    z-index: 1;
    transition: all var(--animation-speed-normal) ease-in-out;
    cursor: pointer;
  }
  
  .card[active] {
    height: 60vw;
  }
  
  .card__image {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    filter: brightness(.675) saturate(75%);
    transition: filter var(--animation-speed-fast) ease-in-out;
  }
  
  .card:hover .card__image {
    filter: brightness(.875) saturate(100%);
  }
  
  .card__infos {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    height: var(--cards-closed-size);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: .125rem;
    width: 100%;
    padding: .25rem 1rem;
  }
  
  .card__name {
    margin: 0;
    color: var(--cards-text-color);
    transform: translateY(.65rem);
    transition: all var(--animation-speed-normal) ease-in-out;
    font-size: 1.7rem;
  }
  
  .card[active] .card__name {
    transform: translateY(0);
  }
  
  .card__author {
    margin: 0;
    font-size: 2rem;
    color: var(--cards-text-color);
    text-decoration: none;
    visibility: hidden;
    opacity: 0;
    transition:
      opacity var(--animation-speed-fast) ease-in-out,
      transform var(--animation-speed-normal) ease-in-out;
  }
  
  .card[active] .card__author {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  
  .card[active] .card__author:hover {
    opacity: .75;
  } 
  
  @media screen and (min-width: 640px) {
    .cards {
      flex-direction: row;
      justify-content: center;
    }
  
    .card {
      width: var(--cards-closed-size);
      height: 29rem;
      flex: 0 0 var(--cards-closed-size);
    }
  
    .card[active] {
      width: 100%;
      height: 29rem;
      flex: 0 0 calc(640px - 10rem);
    }
    
    .card__name {
      transform: rotate(-90deg) translate(1rem, -2.25rem);
    }
  }  
  
  
  /* 3 lines to X */
  
  .nav-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30px;
    width: 30px;
    margin: auto;
    margin-top: 20px;
  }
  
  .nav-toggle .bar {
    height: 4px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    transition: all 100ms ease-in-out;
  }
  
  .nav-toggle:hover {
    cursor: pointer;
  }
  
  .x:nth-of-type(1) {
    transition: all 100ms ease-in-out;
    transform: rotate(45deg);
    transform-origin: top left;
    width: 40px;
  }
  
  .x:nth-of-type(2) {
    transition: all 100ms ease-in-out;
    transform-origin: center;
    width: 0;
  }
  
  .x:nth-of-type(3) {
    transition: all 100ms ease-in-out;
    transform: rotate(-45deg);
    transform-origin: bottom left;
    width: 40px;
  }
  
  /* media queries */
  
  
  
  @media (max-width: 991px) {
    .toggle {
      display: block;
    }
  
    .topbar {
      background: rgb(5, 125, 125);
    }
  
    /* .navigation {
      left: -300px;
    }
  
    .navigation.active {
      width: 300px;
      left: 0;
    } */
  
    /* .card {
      height: 240px;
      width: 300px;
    } */
  
    /* .main {
      width: 100%;
      left: 0;
    }
  
    .main.active {
      left: 300px;
    } */
  /* 
    .toggle {
      display: block;
    }
  
    .header-title {
      display: flex;
    } */
  }
  
  /* @media (max-width: 720px) {
    .card {
      height: 230px;
      width: 220px;
      font-size: 16px;
      margin: 10px;
    }
  } */
  
  /* @media (max-width: 480px) {
    .card {
      height: 230px;
      width: 160px;
      font-size: 16px;
      margin: 10px;
    }
  } */
  
  @media (max-width: 400px) {
    /* .header-title {
      display: flex;
    }
  
    .navigation {
      width: 100%;
      left: -100%;
      z-index: 1000;
    }
  
    .navigation.active {
      width: 100%;
      left: 0;
    }
  
    .topbar {
      background: rgb(5, 125, 125);
    }
  
    .toggle {
      z-index: 10001;
      display: block;
    }
  
    .main.active .toggle {
      position: fixed;
    }
  
    .main img {
      height: 35px;
    } */
  }
  
  /* @media (max-width: 991px)
  {
      .graphBox{
          grid-template-columns: 1fr;
          height: auto;
      }
  } */
  