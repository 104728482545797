.mainContainer {
    margin: 0;
    height: 100vh;
    font-weight: 100;
    background: linear-gradient(0deg, #065656 0%, #038181 100%);
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 0.35fr 0.65fr;
    position: relative;
}

.leftContainer {
    /* background: #065656; */
    /* background: linear-gradient(0deg, #065656 0%, #038181 100%); */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.leftContainer img {
    width: 80%;
}

.rightContainer {
    padding: 20px 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.titleSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.titleSection h1 {
    font-size: 2rem;
    font-weight: 600;
    color: #065656;
    margin-bottom: 10px;
}

.titleSection .logo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* background-color: light grey */
    background-color: #eef1f7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleSection .logo>img {
    width: 55px;
    height: 55px;
}

.inputSection {
    width: 100%;
    display: flex;
    gap: clamp(10px, 3%, 50px);
}

.inputSection .formSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.inputSection p {
    font-size: 0.8rem;
    font-weight: 600;
    color: #065656;
}

.inputSection input,
.inputSection select,
.profileSelect {
    display: block;
    width: 80%;
    min-width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #065656;
    outline: none;
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #065656;
    /* margin-bottom: 10px; */
}

 .profileSelect {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

 .profileImage {
    display: none !important;
}

.inputSection button {
    width: 80%;
    min-width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #065656;
    outline: none;
    background: #065656;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.inputSection button:hover {
    background: #fff;
    color: #065656;
}

.forgotPassword a {
    font-size: 0.8rem;
    font-weight: 600;
    color: #065656;
    margin-top: 10px;
}

.signupSection {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background: #fff;
   font-size: 0.8rem;
    }

.signupSection a {
    font-weight: 600;
    color: #065656;
    margin-top: 10px;
}

.errorMessage p {
    color: red;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
    animation: horizontalShaking 1.5s forwards;
}

@media screen and (max-width: 1024px) {
    .mainContainer {
        grid-template-columns: 1fr;
    }

    .leftContainer {
        display: none;
    }

    .rightContainer {
        width: 100%;
    }

}

@media screen and (max-width: 768px) {
    .leftContainer{
        display: flex;
        height: 30vh;
    }
    .leftContainer img{
        width: auto;
        height: 80%;
    }
    .rightContainer{
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        
    }
    .inputSection {
        flex-direction: column;
    }

    .inputSection input,
    .inputSection select,
    .profileSelect {
        width: 100%;
    }

    .titleSection {
        margin-bottom: 20px;
    }

    .signupSection {
        position: relative;
    }
}

@keyframes horizontalShaking {
    0% {
        transform: translateX(0)
    }

    25% {
        transform: translateX(5px)
    }

    50% {
        transform: translateX(-5px)
    }

    75% {
        transform: translateX(5px)
    }

    100% {
        transform: translateX(0)
    }
}

.profileImageInput {
    border: 0;
}


/* Background */
.light {
position: absolute;
width: 0px;
opacity: .75;
background-color: white;
box-shadow: #e9f1f1 0px 0px 20px 2px;
opacity: 0;
top: 100vh;
bottom: 0px;
left: 0px;
right: 0px;
margin: auto;
}

.x1{
-webkit-animation: floatUp 4s infinite linear;
-moz-animation: floatUp 4s infinite linear;
-o-animation: floatUp 4s infinite linear;
animation: floatUp 4s infinite linear;
-webkit-transform: scale(1.0);
-moz-transform: scale(1.0);
-o-transform: scale(1.0);
transform: scale(1.0);
}

.x2{
-webkit-animation: floatUp 7s infinite linear;
-moz-animation: floatUp 7s infinite linear;
-o-animation: floatUp 7s infinite linear;
animation: floatUp 7s infinite linear;
-webkit-transform: scale(1.6);
-moz-transform: scale(1.6);
-o-transform: scale(1.6);
transform: scale(1.6);
left: 15%;
}

.x3{
-webkit-animation: floatUp 2.5s infinite linear;
-moz-animation: floatUp 2.5s infinite linear;
-o-animation: floatUp 2.5s infinite linear;
animation: floatUp 2.5s infinite linear;
-webkit-transform: scale(.5);
-moz-transform: scale(.5);
-o-transform: scale(.5);
transform: scale(.5);
left: -15%;
}

.x4{
-webkit-animation: floatUp 4.5s infinite linear;
-moz-animation: floatUp 4.5s infinite linear;
-o-animation: floatUp 4.5s infinite linear;
animation: floatUp 4.5s infinite linear;
-webkit-transform: scale(1.2);
-moz-transform: scale(1.2);
-o-transform: scale(1.2);
transform: scale(1.2);
left: -34%;
}

.x5{
-webkit-animation: floatUp 8s infinite linear;
-moz-animation: floatUp 8s infinite linear;
-o-animation: floatUp 8s infinite linear;
animation: floatUp 8s infinite linear;
-webkit-transform: scale(2.2);
-moz-transform: scale(2.2);
-o-transform: scale(2.2);
transform: scale(2.2);
left: -57%;
}

.x6{
-webkit-animation: floatUp 3s infinite linear;
-moz-animation: floatUp 3s infinite linear;
-o-animation: floatUp 3s infinite linear;
animation: floatUp 3s infinite linear;
-webkit-transform: scale(.8);
-moz-transform: scale(.8);
-o-transform: scale(.8);
transform: scale(.8);
left: -81%;
}

.x7{
-webkit-animation: floatUp 5.3s infinite linear;
-moz-animation: floatUp 5.3s infinite linear;
-o-animation: floatUp 5.3s infinite linear;
animation: floatUp 5.3s infinite linear;
-webkit-transform: scale(3.2);
-moz-transform: scale(3.2);
-o-transform: scale(3.2);
transform: scale(3.2);
left: 37%;
}

.x8{
-webkit-animation: floatUp 4.7s infinite linear;
-moz-animation: floatUp 4.7s infinite linear;
-o-animation: floatUp 4.7s infinite linear;
animation: floatUp 4.7s infinite linear;
-webkit-transform: scale(1.7);
-moz-transform: scale(1.7);
-o-transform: scale(1.7);
transform: scale(1.7);
left: 62%;
}

.x9{
-webkit-animation: floatUp 4.1s infinite linear;
-moz-animation: floatUp 4.1s infinite linear;
-o-animation: floatUp 4.1s infinite linear;
animation: floatUp 4.1s infinite linear;
-webkit-transform: scale(0.9);
-moz-transform: scale(0.9);
-o-transform: scale(0.9);
transform: scale(0.9);
left: 85%;
}


@-webkit-keyframes floatUp{
0%{top: 100vh; opacity: 0;}
25%{opacity: 1;}
50%{top: 0vh; opacity: .8;}
75%{opacity: 1;}
100%{top: -100vh; opacity: 0;}
}
@-moz-keyframes floatUp{
0%{top: 100vh; opacity: 0;}
25%{opacity: 1;}
50%{top: 0vh; opacity: .8;}
75%{opacity: 1;}
100%{top: -100vh; opacity: 0;}
}
@-o-keyframes floatUp{
0%{top: 100vh; opacity: 0;}
25%{opacity: 1;}
50%{top: 0vh; opacity: .8;}
75%{opacity: 1;}
100%{top: -100vh; opacity: 0;}
}
@keyframes floatUp{
0%{top: 100vh; opacity: 0;}
25%{opacity: 1;}
50%{top: 0vh; opacity: .8;}
75%{opacity: 1;}
100%{top: -100vh; opacity: 0;}
}

@keyframes horizontal-shaking {
0% { transform: translateX(0) }
25% { transform: translateX(5px) }
50% { transform: translateX(-5px) }
75% { transform: translateX(5px) }
100% { transform: translateX(0) }
}