.mainContainer {
    padding: 20px;
    margin-left: 75px;
    /* background-color: var(--dull-white); */
}
.mainTop {
    background-color: var(--primary-white);
    padding: 20px;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 25px;
}
.topna {
    margin-left: 75px;
    display: flex;
    justify-content: space-between;
    color: var(--green);
  }
  
  .topna p {
    padding: 20px;
    font-family: 'Space Grotesk', sans-serif;
  }
  
  .topna .rightNav {
    padding: 20px;
  }
  
  .rightNav {
    display: flex;
  }
  
  .pro {
    display: flex;
  }
  
  .pro p {
    padding: 0;
    padding: 0 10px;
  }
  
  .pro img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 10px;
    margin-top: 3px;
  }
  
  .main {
    margin-left: 75px;
    display: flex;
  }
  
  .profile {
    position: fixed;
    /* width: 380px; */
    /* height: 100vh; */
    padding: 30px;
    font-family: 'Space Grotesk', sans-serif;
    color: var(--black);
    font-weight: 400;
  }
  
  .pic {
    text-align: center;
  }
  
  .pic p {
    text-transform: uppercase;
    margin: 20px;
    font-size: 20px;
  }
  
  .pic img {
    width: 120px;
    height: 120px;
    margin-top: 40px;
  }
  
  .icons {
    display: flex;
    justify-content: space-evenly;
    margin: 40px 2px;
  }
  
  .icons button {
    outline: none;
    border: none;
    background-color: var(--green);
    color: var(--white);
    padding: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 1s;
  }
  
  .icons button:hover,
  .change button:hover {
    background-color: var(--white);
    color: var(--green);
    box-shadow: rgba(5, 122, 122, 0.35) 0px 5px 15px;
  }
  
  ion-icon {
    width: 25px;
    height: 25px;
  }
  
  .details {
    margin: 20px;
  }
  
  .details p {
    font-size: 15px;
    margin: 10px;
  }
  
  .details p span {
    color: var(--grey);
    font-size: 15px;
  }
  
  .change {
    display: flex;
    justify-content: space-evenly;
  }
  
  .change button {
    padding: 10px;
    outline: none;
    margin: 20px 8px;
    font-size: 16px;
    border: none;
    background-color: var(--green);
    color: var(--white);
    transition-duration: 1s;
    border-radius: 10px;
  }
  
  .subSections {
    font-family: 'Space Grotesk', sans-serif;
    margin-left: 380px;
    border-left: solid 1px #00000028;
  }
  
  .subSections ul {
    margin-left: 10px;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
  }
  
  .subSections ul li {
    margin: 20px;
    color: var(--grey);
    font-size: 18px;
    cursor: pointer;
    padding-bottom: 15px;
    width: fit-content;
    border-bottom-width: 3.1px;
    border-bottom-style: solid;
    border-color: var(--white);
  }
  
  .subActive {
    border-color: var(--green) !important;
    color: var(--black) !important;
  }
  
  /* .tasks,
  .history {
    display: none;
  } */
  
  .team {
    margin: 15px;
    padding: 15px;
    border-left: solid 1px #00000028;
    position: relative;
  }
  
  .team .title {
    display: flex;
    justify-content: space-between;
    color: var(--grey);
  }
  
  .team .title p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .team .taskIcons {
    position: absolute;
    top: -10px;
    padding: 5px;
    left: -10px;
    color: var(--white);
    background-color: var(--green);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
  
  .team .text {
    font-size: 15px;
  }
  
  @media screen and (min-width: 320px) and (max-width: 450px) {
    .profile {
      padding: 15px;
    }
  
    .details {
      margin: 5px;
    }
  
    .details p {
      margin: 0;
    }
  
    .icons button {
      padding: 8px;
    }
  
    .pic img {
      width: 80px;
      height: 80px;
    }
  
    .subSections ul {
      margin-left: 10px;
    }
  
    .subSections ul li {
      margin: 10px;
    }
  
    .team {
      margin: 15px;
    }
  }
  
  @media screen and (min-width: 320px) and (max-width: 790px) {
    .profile {
      position: initial;
    }
  
    .main {
      flex-direction: column;
    }
  
    .subSections {
      margin-left: 0;
    }
  }
  