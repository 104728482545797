.mainContainer {
    background-color: var(--dull-white);
    padding: 20px;
    margin-left: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  
  .mainTop {
    width: 100%;
    background-color: var(--primary-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .formDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .inputSection {
    width: 100%;
    display: flex;
    gap: 5%;
    justify-content: center;
    flex-direction: column;
  }
  
  .inputSection .formSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  .inputSection p {
    font-size: 0.8rem;
    font-weight: 600;
    color: #065656;
  }
  
  .inputSection input,
  .inputSection select,
  .inputSection label.carouselSelect {
    display: block;
    width: 100%;
    min-width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #065656;
    outline: none;
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #065656;
  }
  
  .inputSection label.carouselSelect {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inputSection .carouselImage {
    display: none;
  }
  
  .submitBtn {
    width: 100%;
    max-width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #065656;
    outline: none;
    background: #065656;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .inputSection button:hover {
    background: #fff;
    color: #065656;
  }
  
  .forgotPassword a {
    font-size: 0.8rem;
    font-weight: 600;
    color: #065656;
    margin-top: 10px;
  }
  
  .profileImageInput {
    border: 0;
  }
  
  @media screen and (max-width: 768px) {
    .inputSection {
      flex-direction: column;
    }
  
    .inputSection input,
    .inputSection select,
    .inputSection label.carouselSelect {
      width: 100%;
    }
  }
  
  .addCollege {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .addCollege h2 {
    font-size: 150%;
    color: #065656;
  }
  
  .fieldsWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
  }
  
  .addCollegeForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .addCollegeForm label {
    font-size: 125%;
    margin: 0.25rem 0;
  }
  
  .addCollegeForm input {
    width: 20vw;
    margin-bottom: 1.25rem;
    padding: 0.25rem;
  }
  
  .addCollegeForm button {
    background-color: #065656;
    padding: 0.25rem 0.5rem;
    border-radius: 25px;
    color: #fff !important;
    cursor: pointer;
  }
  
  .errorMessage {
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    background-color: #065656;
    color: #fff !important;
    animation: horizontalShaking 1.5s forwards;
  }
  
  select {
    width: 100%;
  }

  @keyframes horizontalShaking {
    0% {
      transform: translateX(0)
    }

    25% {
      transform: translateX(5px)
    }

    50% {
      transform: translateX(-5px)
    }

    75% {
      transform: translateX(5px)
    }

    100% {
      transform: translateX(0)
    }
  }
  