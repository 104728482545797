.mainContainer {
    display: grid;
    padding: 30px;
    grid-template-columns: 1fr 1fr;
    margin-left: 75px;
    gap: 30px;
  }
  
  .card {
    overflow: hidden;
    width: 100%;
    filter: contrast(75%);
    transition: filter 0.5s ease-in-out;
    height: 450px;
    border-radius: 1rem;
  }
  
  .card:hover {
    filter: contrast(100%);
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card .cardTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    color: white;
    font-size: 2rem;
  }
  
  @media screen and (max-width: 1024px) {
    .mainContainer {
      grid-template-columns: 1fr;
    }
  
    .card {
      height: 350px;
    }
  
    .card .cardTitle {
      font-size: 1.5rem;
    }
  }
  
  @media screen and (max-width: 576px) {
    .card {
      height: 250px;
    }
  }
  