.mainContainer {
    padding: 20px;
    margin-left: 75px;
    transition: margin-left 1s ease;
    background-color: var(--dull-white);
  }
  
  .mainTop {
    background-color: var(--primary-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .addCollege {
    text-decoration: none;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--sellular-primary);
    border: 2px solid var(--sellular-primary);
    color: var(--primary-white);
    padding: 10px 10px;
    border-radius: 10px;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
  }
  
  .tableDivOuter {
    margin-top: 20px;
    background-color: var(--primary-white);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow-x: auto;
  }
  
  .tableDivTop {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .tableDivTop > form {
    display: flex;
    align-items: center;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 5px 5px;
  }
  
  .tableDivTop input,
  .tableDivTop select {
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 600;
    background-color: transparent;
    appearance: none;
  }
  
  .tableDivTop select option {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .tableMain {
    width: 100%;
    border-collapse: collapse;
    overflow-x: scroll;
  }
  
  .tableMain thead {
    background-color: var(--light-grey);
    height: 50px;
    overflow-x: scroll;
  }
  
  .tableMain thead th {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    padding: 0 10px;
  }
  
  .tableMain tbody {
    overflow-x: scroll;
  }
  
  .tableMain tbody tr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    height: 50px;
  }
  
  .tableMain tbody tr td {
    padding: 0 10px;
  }
  
  .applicantTitle {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .profileIcon {
    height: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
  
  .profileIcon img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
    flex-shrink: 0;
  }
  
  .tableMain tbody .adminBtn {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--sellular-primary);
    border: 2px solid var(--sellular-primary);
    color: var(--primary-white);
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination ul {
    display: flex;
    justify-content: center;
    list-style: none;
    background-color: var(--primary-white);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .pagination ul li {
    min-width: 20px;
    padding: 5px 15px;
    cursor: pointer;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .pagination ul li:first-child {
    border-left: none;
  }
  
  .pagination ul li:hover {
    background-color: var(--light-grey);
  }
  
  .pagination ul li.disable {
    display: none;
  }
  
  /* Media Queries */
  @media screen and (max-width: 1200px) {
    .applicationList {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .tableDivOuter table thead tr th:nth-child(2),
    .tableDivOuter table tbody tr td:nth-child(2) {
      display: none;
    }
  }
  
  @media screen and (max-width: 768px) {
    .mainMainContainer {
      grid-template-columns: 1fr;
    }
  
    aside {
      display: none;
    }
  
    .applicationList {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .tableDivOuter table thead tr th:nth-child(2),
    .tableDivOuter table tbody tr td:nth-child(2),
    .tableDivOuter table thead tr th:nth-child(3),
    .tableDivOuter table tbody tr td:nth-child(3) {
      display: none;
    }
  }
  
  @media screen and (max-width: 576px) {
    .mainContainer {
      padding: 10px;
    }
  
    table.tableMain thead th {
      padding: 0px;
      font-size: 0.6rem;
    }
  
    table.tableMain tbody tr td p,
    table.tableMain tbody tr td {
      padding: 0px;
      font-size: 0.6rem;
    }
  
    .tableDivTop {
      padding: 10px 5px;
    }
  
    .tableDivTop > form {
      padding: 5px;
    }
  
    .tableDivTop input,
    .tableDivTop select {
      font-size: 0.6rem;
      font-weight: 400;
    }
  
    #modal .modalLeft {
      height: 150px;
      width: 150px;
    }
  
    #modal .modalRight {
      font-size: 0.6rem;
    }
  
    table.tableMain tbody .adminBtn {
      font-size: 0.6rem;
      padding: 3px 5px;
      font-weight: 400;
    }
  
    .pagination li {
      font-size: 0.6rem;
    }
  }
  